import React from 'react'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'

const timeout = 150
const styles = {
  entering: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    opacity: 0
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0
  }
}

const Transition = ({ children, location }) => <TransitionGroup>
  <ReactTransition
    key={location.pathname}
    timeout={{ enter: timeout, exit: timeout }}
  >
    {status =>
      <div style={styles[status]}>
        {children}
      </div>
    }
  </ReactTransition>
</TransitionGroup>

export default ({ children, location }) =>
  <Transition location={location}>
    {children}
  </Transition>