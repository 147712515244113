exports.onInitialClientRender = () => {
  window.scrollTo(0, 0)
}

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    setTimeout(() => window.location.href = location.href, 200)
    setTimeout(() => window.location.href = location.href, 300)
  } else
    setTimeout(() => window.scrollTo(0, 0), 150)
  return false
}